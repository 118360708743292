import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import PageTemplate2 from "../templates/page2"
import theme from "../gatsby-theme-material-ui-top-layout/theme"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"

const useStyles = makeStyles(theme => ({
  htmlP: {
    "& p": {
      marginTop: 8,
      marginBottom: 8,
    },
    "& p:first-child": {
      marginTop: 0,
    },
    "& p:last-child": {
      marginBottom: 0,
    },
  },
}))

const PeopleCard = ({
  personTitle,
  portrait,
  designation,
  excerpt,
  order,
  personHtml,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const classes = useStyles()
  return (
    <>
      <Card variant="outlined" style={{ height: "100%" }}>
        <CardActionArea
          onClick={() => (personHtml ? setShowDialog(true) : null)}
          disabled={order === 1 || !personHtml}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: personHtml ? "flex-end" : "flex-start",
            height: "100%",
          }}
        >
          <CardContent>
            <Box display="flex" flexWrap="wrap" pb={2}>
              {portrait && (
                <Box pb={{ xs: 2, md: 0 }}>
                  <Avatar
                    variant="rounded"
                    alt={personTitle}
                    style={{ width: 200, height: 200, marginRight: 32 }}
                  >
                    <GatsbyImage image={getImage(portrait)} alt={personTitle} />
                  </Avatar>
                </Box>
              )}
              <Box>
                <Typography
                  variant={order === 1 ? "h2" : "h3"}
                  component="h2"
                  style={{ paddingBottom: 0 }}
                >
                  {personTitle}
                </Typography>
                {designation && (
                  <Typography
                    variant="overline"
                    style={{
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      color: theme.palette.text.secondary,
                      lineHeight: 1,
                    }}
                  >
                    {designation}
                  </Typography>
                )}
              </Box>
            </Box>
            <Typography className={classes.htmlP}>
              {excerpt ? (
                <p>{excerpt}</p>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: personHtml,
                  }}
                />
              )}
            </Typography>
          </CardContent>
          {personHtml && order > 1 && (
            <CardActions>
              <Button
                variant="text"
                disableRipple
                endIcon={<ArrowForwardIcon />}
              >
                Read more
              </Button>
            </CardActions>
          )}
        </CardActionArea>
      </Card>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogTitle>
          <Typography variant="h5">{personTitle}</Typography>
          <Typography variant="subtitle1">{designation}</Typography>
          <IconButton
            onClick={() => setShowDialog(false)}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText gutterBottom>
            <div
              className={classes.htmlP}
              dangerouslySetInnerHTML={{ __html: personHtml }}
            />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  )
}

const PeoplePage = () => {
  const {
    file: {
      childMarkdownRemark: {
        frontmatter: { title, coverImage, imageCrop },
        html,
      },
    },
    allFile: { nodes },
  } = useStaticQuery(
    graphql`
      {
        file(name: { eq: "people" }) {
          childMarkdownRemark {
            frontmatter {
              title
              coverImage {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              imageCrop
            }
            html
          }
        }
        allFile(
          filter: { sourceInstanceName: { eq: "people" } }
          sort: { fields: childMarkdownRemark___frontmatter___order }
        ) {
          nodes {
            childMarkdownRemark {
              frontmatter {
                portrait {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                title
                designation
                order
                excerpt
              }
              html
            }
          }
        }
      }
    `
  )

  return (
    <PageTemplate2 title={title} coverImage={coverImage} imageCrop={imageCrop}>
      <Grid container style={{ marginTop: 16 }}>
        {nodes.map(
          (
            {
              childMarkdownRemark: {
                frontmatter: {
                  title: personTitle,
                  designation,
                  portrait,
                  order,
                  excerpt
                },
                html: personHtml,
              },
            },
            index
          ) => (
            <Grid key={personTitle} item xs={12} md={order === 1 ? 12 : 6}>
              <PeopleCard
                portrait={portrait}
                personTitle={personTitle}
                designation={designation}
                order={order}
                personHtml={personHtml}
                excerpt={excerpt}
              />
            </Grid>
          )
        )}
      </Grid>
      <Box pt={2} dangerouslySetInnerHTML={{ __html: html }} />
    </PageTemplate2>
  )
}

export default PeoplePage
